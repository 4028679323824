import { filter, pick } from "lodash";

export const UserRoleDefaults = [
  {
    id: 1,
    role: "Admin",
  },
  {
    id: 2,
    role: "Director",
  },
  {
    id: 3,
    role: "Executive Assistant",
  },
  {
    id: 4,
    role: "Admin & HR Manager",
  },
  {
    id: 5,
    role: "HR Assistant",
  },
  {
    id: 6,
    role: "Employee",
  },
  {
    id: 7,
    role: "Finance Head",
  },
  {
    id: 8,
    role: "Accounting Staff",
  },
  {
    id: 9,
    role: "EARD Program Manager",
  },
  {
    id: 10,
    role: "EARD Program Coordinator",
  },
  {
    id: 11,
    role: "EARD Admin Assistant",
  },
  {
    id: 12,
    role: "EARD MVP Officer",
  },
  {
    id: 13,
    role: "CISD Program Manager",
  },
  {
    id: 14,
    role: "CISD Program Coordinator",
  },
  {
    id: 15,
    role: "Doctor",
  },
  {
    id: 16,
    role: "Clinical Assistant",
  },
  {
    id: 17,
    role: "Supervising Psychologist",
  },
  {
    id: 18,
    role: "Chapter Coordinator",
  },
  {
    id: 19,
    role: "IEA",
  },
  {
    id: 20,
    role: "Client / Service User",
  },
];

export const employeeFileManagementRoles = [2, 4, 7, 9, 13];

export const JobCategories = [
  {
    id: 1,
    name: "Executive/Senior Level Officials and Managers",
  },
  {
    id: 2,
    name: "First/Mid-Level Officials and Managers",
  },
  {
    id: 3,
    name: "Professionals",
  },
  {
    id: 4,
    name: "Technicians",
  },
  {
    id: 5,
    name: "Sales Workers",
  },
  {
    id: 6,
    name: "Administrative Support Workers",
  },
  {
    id: 7,
    name: "Craft Workers",
  },
  {
    id: 8,
    name: "Operatives",
  },
  {
    id: 9,
    name: "Laborers and Helpers",
  },
  {
    id: 10,
    name: "Service Workers",
  },
];

export const FormTypes = [
  {
    id: 1,
    name: "Form Approvals",
  },
  {
    id: 2,
    name: "Survey/Feedback Form",
  },
  {
    id: 3,
    name: "Generic Form",
  },
];

export const WorkflowTypes = [
  {
    id: 1,
    name: "Approval",
  },
  // {
  //     id: 2,
  //     name: 'Notification',
  // },
  // {
  //     id: 3,
  //     name: 'Milestone',
  // },
];

export const AppVersion = "v1.0.0";

export const sizeLimit = 3 * 1024 * 1024; //3mb
export const allowedFileTypes = "pdf";

export function maxFileSize(sizeLimit) {
  return function (fileInfo) {
    if (fileInfo.size > sizeLimit) {
      throw new Error("maxFileLimit");
    }
  };
}

export const fileTypeLimit = (allowedFileTypes) => {
  const types = allowedFileTypes.split(",");

  return function (fileInfo) {
    if (fileInfo.name === null) {
      return;
    }
    const extension = fileInfo.name.split(".").pop();

    if (extension && !types.includes(extension)) {
      throw new Error("fileType");
    }
  };
};

export const validators = [
  maxFileSize(sizeLimit),
  fileTypeLimit(allowedFileTypes),
];
export const localeError = {
  errors: {
    maxFileLimit: "File is too big. Max file size is 3mb",
    fileType: "Please upload a pdf file",
  },
  dialog: {
    tabs: {
      preview: {
        error: {
          maxFileLimit: {
            title: "Error.",
            text: "Max File is 3mb.",
            back: "Back",
          },
          fileType: {
            title: "Error",
            text: "Please upload a pdf file",
            back: "Back",
          },
        },
      },
    },
  },
};

const FORM_ATTACHMENT_MAX_SIZE = 5 * 1024 * 1024; //5mb
const FORM_ATTACHMENT_ALLOWED_FILE_TYPES = "pdf,doc,docx,png,jpg,jpeg";
export const FORM_ATTACHMENT_VALIDATORS = [
  maxFileSize(FORM_ATTACHMENT_MAX_SIZE),
  fileTypeLimit(FORM_ATTACHMENT_ALLOWED_FILE_TYPES),
];
export const FORM_LOCALE_ERRORS = {
  errors: {
    maxFileLimit: `File is too big. Max file size is ${
      FORM_ATTACHMENT_MAX_SIZE / 1024 / 1024
    }mb`,
    fileType: `Please upload a ${FORM_ATTACHMENT_ALLOWED_FILE_TYPES} type of file`,
  },
  dialog: {
    tabs: {
      preview: {
        error: {
          maxFileLimit: {
            title: "Error.",
            text: `Max File is ${FORM_ATTACHMENT_MAX_SIZE / 1024 / 1024}mb.`,
            back: "Back",
          },
          fileType: {
            title: "Error",
            text: `Please upload a ${FORM_ATTACHMENT_ALLOWED_FILE_TYPES} type of file`,
            back: "Back",
          },
        },
      },
    },
  },
};

export const verificationOption = [
  {
    order: 0,
    label: "For Confirmation",
    value: "For Confirmation",
  },
  {
    order: 1,
    label: "Verified",
    value: "Verified",
  },
  {
    order: 2,
    label: "Replied",
    value: "Replied",
  },
  {
    order: 3,
    label: "Processing Certificate",
    value: "Processing Certificate",
  },
  {
    order: 4,
    label: "Completed",
    value: "Completed",
  },
];
export const mediaCategory = [
  {
    label: "Applicants",
    value: "Applicants",
  },
  {
    label: "Blog",
    value: "Blog",
  },
  {
    label: "Booking",
    value: "Booking",
  },
  {
    label: "Categories",
    value: "Categories",
  },
  {
    label: "Donation",
    value: "Donation",
  },
  {
    label: "Employee",
    value: "Employee",
  },
  {
    label: "Membership",
    value: "Membership",
  },
  {
    label: "User",
    value: "User",
  },
];

export const INQUIRY_STATUS = [
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "Answered",
    value: "Answered",
  },
];

export const INQUIRY_OPTION = [
  {
    label: "General",
    value: "General",
  },
  {
    label: "Partnership and Donations",
    value: "Partnership and Donations",
  },
  {
    label: "Lecture / Training / Advocacy / Research",
    value: "Lecture / Training / Advocacy / Research",
  },
  {
    label: "Membership",
    value: "Membership",
  },
  {
    label: "Job Vacancies",
    value: "Job Vacancies",
  },
  {
    label: "Consultations / Bookings / Service Fee / Internship",
    value: "Consultations / Bookings / Service Fee / Internship",
  },
];

export const INQUIRY_OPTION_BASED_ON_USER_ROLE = {
  1: INQUIRY_OPTION,
  2: INQUIRY_OPTION,
  3: [
    {
      label: "Partnership and Donations",
      value: "Partnership and Donations",
    },
  ],
  4: [
    {
      label: "Job Vacancies",
      value: "Job Vacancies",
    },
  ],
  5: [
    {
      label: "Job Vacancies",
      value: "Job Vacancies",
    },
  ],
  9: [
    {
      label: "Lecture / Training / Advocacy / Research",
      value: "Lecture / Training / Advocacy / Research",
    },
    {
      label: "Membership",
      value: "Membership",
    },
    {
      label: "Partnership and Donations",
      value: "Partnership and Donations",
    },
  ],
  10: [
    {
      label: "Lecture / Training / Advocacy / Research",
      value: "Lecture / Training / Advocacy / Research",
    },
    {
      label: "Membership",
      value: "Membership",
    },
    {
      label: "Partnership and Donations",
      value: "Partnership and Donations",
    },
  ],
  11: [
    {
      label: "Lecture / Training / Advocacy / Research",
      value: "Lecture / Training / Advocacy / Research",
    },
    {
      label: "Membership",
      value: "Membership",
    },
    {
      label: "Partnership and Donations",
      value: "Partnership and Donations",
    },
  ],
  12: [
    {
      label: "Membership",
      value: "Membership",
    },
  ],
  13: [
    {
      label: "Consultations / Bookings / Service Fee / Internship",
      value: "Consultations / Bookings / Service Fee / Internship",
    },
  ],
  14: [
    {
      label: "Consultations / Bookings / Service Fee / Internship",
      value: "Consultations / Bookings / Service Fee / Internship",
    },
  ],
  16: [
    {
      label: "Consultations / Bookings / Service Fee / Internship",
      value: "Consultations / Bookings / Service Fee / Internship",
    },
  ],
  17: [
    {
      label: "Consultations / Bookings / Service Fee / Internship",
      value: "Consultations / Bookings / Service Fee / Internship",
    },
  ],
  22: [
    {
      label: "Consultations / Bookings / Service Fee / Internship",
      value: "Consultations / Bookings / Service Fee / Internship",
    },
  ],
  23: [
    {
      label: "Consultations / Bookings / Service Fee / Internship",
      value: "Consultations / Bookings / Service Fee / Internship",
    },
  ],
};

export const MEMBERSHIP_TYPE = [
  {
    name: "Associate - Junior High School",
    price: "₱20.00",
  },
  {
    name: "Associate - Senior High School",
    price: "₱50.00",
  },
  {
    name: "Associate - College",
    price: "₱100.00",
  },
  {
    name: "Contributing",
    price: "₱300.00",
  },
  {
    name: "Life",
    price: "₱3,000.00",
  },
  {
    name: "Sustaining",
    price: "₱3,000.00",
  },
  {
    name: "Corporate",
    price: "₱10,000.00",
  },
];

export const membershipPaymentStatus = [
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "Verified",
    value: "Verified",
  },
];

export const donationPaymentStatus = [
  {
    label: "Pending",
    value: "Pending",
  },
  {
    label: "Verified",
    value: "Verified",
  },
];

export const blogStatus = [
  {
    label: "Draft",
    value: "Draft",
  },
  {
    label: "Scheduled",
    value: "Scheduled",
  },
  {
    label: "Published",
    value: "Published",
  },
];

export const employeePositions = [
  {
    label: "National Executive Director",
    value: 7,
  },
  {
    label: "Executive Assistant",
    value: 8,
  },
  {
    label: "Chapter Coordinator",
    value: 9,
  },
  {
    label: "Administrative and HR Manager",
    value: 10,
  },
  {
    label: "HR Assistant",
    value: 11,
  },
  {
    label: "MIS Specialist",
    value: 12,
  },
  {
    label: "Property and Purchasing Officer",
    value: 13,
  },
  {
    label: "Driver / Messenger",
    value: 14,
  },
  {
    label: "Multiskilled Technician",
    value: 15,
  },
  {
    label: "Finance Head",
    value: 16,
  },
  {
    label: "Accounting Officer",
    value: 17,
  },
  {
    label: "Accounting Assistant",
    value: 18,
  },
  {
    label: "Acting Program Manager",
    value: 19,
  },
  {
    label: "Acting Psychologist",
    value: 20,
  },
  {
    label: "Case Management Officer",
    value: 21,
  },
  {
    label: "Acting Program Officer",
    value: 22,
  },
  {
    label: "Vocational Assistant",
    value: 23,
  },
  {
    label: "Program Manager",
    value: 24,
  },
  {
    label: "Program Coordinator",
    value: 25,
  },
  {
    label: "Research Head",
    value: 26,
  },
  {
    label: "Research Assistant",
    value: 27,
  },
  {
    label: "Program Head (YLEP)",
    value: 28,
  },
  {
    label: "Program Officer",
    value: 29,
  },
  {
    label: "Acting Program Head (IEA)",
    value: 30,
  },
  {
    label: "Acting Program Head (CBMHP)",
    value: 31,
  },
  {
    label: "Chapter Executive Manager",
    value: 32,
  },
  {
    label: "Administrative Assistant",
    value: 33,
  },
  {
    label: "Psychosocial Frontliner",
    value: 34,
  },
];

export const employeeDepartment = [
  {
    label: "Office of the National Executive Director",
    value: 1,
  },
  {
    label: "Administrative and HR Department",
    value: 2,
  },
  {
    label: "Building Administration Department",
    value: 3,
  },
  {
    label: "Accounting Department",
    value: 4,
  },
  {
    label: "Clinical and Intervention Services Department",
    value: 5,
  },
  {
    label: "Education, Advocacy, and Research Department",
    value: 6,
  },
  {
    label: "ONED - Chapters",
    value: 7,
  },
];

export const bookingSchedules = [
  {
    label: "Available",
    value: "Available",
  },
  {
    label: "Full",
    value: "Full",
  },
];

export const applicantStatus = [
  {
    label: "For Review",
    value: "For Review",
  },
  {
    label: "For Interview",
    value: "For Interview",
  },
  {
    label: "For Final Interview",
    value: "For Final Interview",
  },
  {
    label: "Submission of Requirements",
    value: "Submission of Requirements",
  },
  {
    label: "Hired",
    value: "Hired",
  },
  {
    label: "Not Hired",
    value: "Not Hired",
  },
];

export const APPLICATION_STATUS = [
  "For Review",
  "For Interview",
  "For Final Interview",
  "Submission of Requirements",
  "Hired",
  "Waitlisted",
  "Failed",
  "Not Hired",
  "Not Qualified",
];

export const FORM_LEAVE_ID_REFERENCE = [
  {
    id: 1,
    type: "Vacation Leave",
    sub_type: "No. of days field",
    value: 180, //DEV and PROD
  },
  {
    id: 2,
    type: "Sick Leave",
    sub_type: "No. of days field",
    value: 185, //DEV and PROD
  },
  {
    id: 3,
    type: "Vacation Leave",
    sub_type: "Date From",
    // value: 235, //DEV
    // value: 228, //PROD
    value: 181, //DEV and PROD
  },
  {
    id: 4,
    type: "Vacation Leave",
    sub_type: "Date To",
    // value: 236, //DEV
    // value: 229, //PROD
    value: 182, //DEV and PROD
  },
  {
    id: 5,
    type: "Vacation Leave",
    sub_type: "Reason",
    value: 184, //DEV and PROD
  },

  {
    id: 6,
    type: "Sick Leave",
    sub_type: "Date From",
    // value: 231, //DEV
    // value: 226, //PROD
    value: 186, //DEV and PROD
  },
  {
    id: 7,
    type: "Sick Leave",
    sub_type: "Date To",
    // value: 232, //DEV
    // value: 227, //PROD
    value: 187, //DEV and PROD
  },
  {
    id: 8,
    type: "Sick Leave",
    sub_type: "Reason",
    value: 189, //DEV and PROD
  },
];

export const formRequestType = [
  {
    label: "Vacation Leave Form",
    value: "Vacation Leave Form",
  },
  {
    label: "Sick Leave Form",
    value: "Sick Leave Form",
  },
];
export const formRequestStatus = [
  {
    label: "Approved",
    value: "Approved",
  },
  {
    label: "For Approval",
    value: "For Approval",
  },
  {
    label: "Cancelled",
    value: "Cancelled",
  },
];

export const HONORIFICS = ["Mr", "Ms", "Mrs", "Others"];

export const ITEM_PER_PAGE = 50;
